import SpotifyLogo from "../../assets/spotify-icons-logos/logos/01_RGB/02_PNG/Spotify_Logo_RGB_Green.png";
import Cookies from "js-cookie";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import PlaylistItem from "../Playlist/PlaylistItem";
import { useNavigate } from "react-router";
import { clearTracks } from "../../state/trackState/trackActions";
import { clearSelectedPlaylist } from "../../state/playlistState/playlistActions";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSideBar, setShowSideBar] = useState(false);
  const playlists = useSelector((state) => state.playlists.playlists);
  const selectedPlaylist = useSelector(
    (state) => state.playlists.selectedPlaylist
  );

  const logOut = () => {
    Object.keys(Cookies.get()).forEach(function(cookieName) {
      Cookies.remove(cookieName);
    });
    window.location.reload();
  }

  const handleClick = (playlist) => {
    if (playlist === selectedPlaylist) {
      dispatch(clearTracks());
      dispatch(clearSelectedPlaylist());
      navigate("/");
    } else {
      navigate(`/playlist/${playlist.id}`);
      setShowSideBar(false);
    }
  };
  
  const createListOfPlaylists = () => {
    if (playlists.length === 0) {
      return <p>Loading...</p>;
    } else {
      return playlists.map((playlist) => {
        return (
          <PlaylistItem
            key={playlist.id}
            className={selectedPlaylist === playlist ? "bg-[#414141]" : ""}
            playlist={playlist}
            onClick={() => handleClick(playlist)}
          />
        );
      });
    }
  };

  return (
    <div className="flex items-center border-b-[1px] border-[#5c5c5c] p-3 justify-between">
      <div className="flex items-center toolkit-logo">
        <h2 className="flex flex-row text-xl mr-2">Toolkit for </h2>
        <img className="inline h-[70px]" src={SpotifyLogo} alt="" />
      </div>
      <div>
        <button className="hidden items-center justify-center px-2 py-1 border border-transparent text-base font-medium rounded-md text-white bg-red-700 hover:bg-red-800 transition-all duration-200 sm:block" onClick={logOut}>Log out</button>
        <button onClick={() => setShowSideBar(!showSideBar)} className="block items-center justify-center p-2 border border-zinc-600 text-base font-medium rounded-md text-white bg-transparent hover:outline outline-2 outline-zinc-600 outline-offset-2 transition-all duration-75 sm:hidden"><GiHamburgerMenu className="w-5 h-5" /></button>
      </div>

      <div className={(showSideBar ? "w-full" : "w-0") + " flex sm:hidden flex-row absolute z-10 inset-0 transition-all"}>
        <div className="relative h-full w-1/2 py-1">
          <div className="absolute bg-[#2b2b2b] w-full inset-0">
          </div>
          {showSideBar && (<div className="flex flex-col h-full relative">
            <button className="items-center justify-center w-1/3 px-2 py-1 ml-1 mb-1 border border-transparent text-base font-medium rounded-md text-white bg-red-700 hover:bg-red-800 transition-all duration-200" onClick={logOut}>Log out</button>
            {createListOfPlaylists()}
          </div>)}
        </div>
        <div onClick={() => setShowSideBar(false)} className="w-1/2 backdrop-blur-[2px]"></div>
      </div>
    </div>
  );
}

export default Navbar;