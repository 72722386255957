import axios from "axios";

export const setPlaylists = (value) => {
  return { type: "setPlaylists", payload: value };
};

export const getPlaylists = (token) => {
  return async (dispatch) => {
    const playlists = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND_URL}/playlist`,
      withCredentials: true,
    });

    return dispatch(setPlaylists(playlists.data.message));
  };
};

export const selectPlaylist = (value) => {
  return { type: "selectPlaylist", payload: value };
};

export const setSearchBox = () => {
  return { type: "setSearchBox" };
}

export const clearSelectedPlaylist = () => {
  return { type: "clearSelectedPlaylist" };
}
