import { HiOutlineSearch } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { setSearchBox } from '../../../state/playlistState/playlistActions';

const SearchSong = (props) => {
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(setSearchBox())} className="inline-flex justify-center items-center rounded-md border-2 border-transparent shadow-sm px-4 py-1 text-base text-white outline-zinc-700 hover:outline transition-all duration-75">
      <HiOutlineSearch className="mr-1" /> Search song
    </button>
  );
};

export default SearchSong;