import axios from "axios";
import Cookies from "js-cookie";

export const getOAuthCode = (code, type = "authorization_code") => {
  return async (dispatch) => {
    const oauth = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/${type}`,
      {
        code,
      },
      {
        withCredentials: true,
      }
    );

    if (oauth.data.status !== "error") {
      if (type === "refresh_token") {
        oauth.data.message["refresh_token"] = code;
      }

      for (const [key, value] of Object.entries(oauth.data.message)) {
        Cookies.set(key, value, { expires: new Date(new Date().getTime() + oauth.data.message.expires_in * 1000) });
      }
      
      setTimeout(
        () => dispatch(getOAuthCode(oauth.data.message.refresh_token || Cookies.get("refresh_token"), "refresh_token")),
        oauth.data.message.expires_in * 1000
      );
  
      dispatch(setToken(oauth.data.message));
    }
  };
};

export function setToken(value) {
  return { type: "setToken", payload: value };
}
