import PlaylistFunctions from "./PlaylistFunctions";
import TrackItem from "../Track/TrackItem";
import { getTracksFromSpotify } from "../../state/trackState/trackActions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DEFAULT_VALUES } from "../../utils/enums";

const PlaylistInformation = () => {
  const dispatch = useDispatch();
  const [currectPlaylist, setCurrentPlaylist] = useState({});
  const tracks = useSelector((state) => state.tracks.tracks);
  const selectedPlaylist = useSelector(
    (state) => state.playlists.selectedPlaylist
  );
  const nextURL = useSelector((state) => state.tracks.nextURL);
  const searchBox = useSelector((state) => state.playlists.searchBox);

  useEffect(() => {
    if (selectedPlaylist !== undefined && tracks !== undefined && nextURL !== null) {
      if (Object.keys(selectedPlaylist).length !== 0) {
        setCurrentPlaylist(selectedPlaylist);
        dispatch(getTracksFromSpotify(selectedPlaylist.id, DEFAULT_VALUES.limit, tracks.length));
      }
    }
  }, [dispatch, tracks, selectedPlaylist, nextURL]);

  useEffect(() => {
    if (Object.keys(selectedPlaylist).length === 0 && Object.keys(currectPlaylist).length !== 0) {
      setCurrentPlaylist({});
    }
  }, [selectedPlaylist, currectPlaylist]);

  useEffect(() => {
    if (currectPlaylist !== selectedPlaylist) {
      dispatch(getTracksFromSpotify(selectedPlaylist.id, DEFAULT_VALUES.limit, 0));
    }
  }, [selectedPlaylist, currectPlaylist, dispatch]);

  const tracksToTrackItems = () => {
    if (Object.keys(selectedPlaylist).length === 0) {
      return <h3 className="m-3">Choose a playlist</h3>;
    }
    else if (tracks === undefined || tracks.length === 0) {
      return <p>Loading...</p>;
    } else { 
      let i = 0;
      return tracks
        .filter((track) => track.track.id !== null || track.track !== undefined)
        .map((track) => {
          i++;
          return <TrackItem key={track.track.id + i} track={track} />;
        });
    }
  };

  return (
    <>
      <div className="border-b border-[#5c5c5c] transition-all">
        <PlaylistFunctions />
        {searchBox && (<div className="p-2">
          <input className="w-1/2 p-1 rounded-md bg-[#252525] text-white placeholder:text-zinc-300 ring-[#181818]" type="text" placeholder="Search for a song" />
        </div>)}
      </div>
      {tracksToTrackItems()}
    </>
  );
};

export default PlaylistInformation;
