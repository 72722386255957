import DuplicateCheck from "./PlaylistFunctions/DuplicateCheck";
import SearchSong from "./PlaylistFunctions/SearchSong";

const PlaylistFunctions = (props) => {
  return (
    <div className="flex gap-2 h-[60px] m-0 p-[10px]">
      <SearchSong />
      <DuplicateCheck />
    </div>
  );
};

export default PlaylistFunctions;
