import querystring from "querystring";
import SpotifyLogo from "../assets/spotify-icons-logos/logos/01_RGB/02_PNG/Spotify_Logo_RGB_Green.png";
import { useEffect } from "react";

const LoginPage = () => {
  useEffect(() => {
    document.title = "Loginpage - Toolkit for Spotify";
  }, []);

  const logIn = () => {
    var scope =
      "playlist-read-private playlist-modify-private playlist-modify-public";

    return (
      "https://accounts.spotify.com/authorize?" +
      querystring.stringify({
        response_type: "code",
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: scope,
        redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/loginstatus`,
      })
    );
  };

  return (
    <div className="flex h-2/3 overflow-hidden m-10">
      <div className="flex flex-col self-center mx-auto w-full sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:w-1/4 h-min px-6 py-5 rounded-md border border-zinc-700 text-center gap-5">
        <div className="inline">
          <h1 className="text-4xl">Toolkit for</h1>
          <img src={SpotifyLogo} alt="Spotify logo" />
        </div>
        <hr />
        <p>Sign in with your Spotify account.</p>
        <button 
          onClick={() => window.location.href = logIn()} 
          type="button" 
          className="w-1/2 self-center items-center px-4 py-2 border border-[#1DB954] text-sm font-medium rounded-md shadow-sm text-white hover:outline hover:outline-offset-2 outline-2 outline-[#136b32]"
        >
          Sign in using Spotify
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
