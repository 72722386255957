const initialTrackState = {
  tracks: [],
  nextURL: "",
};

const trackReducer = (state = initialTrackState, action) => {
  switch (action.type) {
    case "setTracks":
      return {
        ...state,
        tracks: action.payload.items,
        nextURL: action.payload.next,
      };
    case "addTracks":
      return {
        ...state,
        tracks: [...(state.tracks ? [...state.tracks] : []), ...action.payload.items],
        nextURL: action.payload.next,
      };
    case "removeTracks":
      const tracks = JSON.parse(JSON.stringify(state.tracks));

      for (let i = action.payload.length - 1; i >= 0; i--) {
        tracks.splice(action.payload[i].props.track.positions[0], 1);
      }

      return {
        ...state,
        tracks: tracks,
      };
    case "clearTracks":
      return {
        ...state,
        tracks: [],
        nextURL: "",
      };

    default:
      return state;
  }
};

export default trackReducer;
