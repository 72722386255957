import PlaylistsList from "./PlaylistsList";
import PlaylistInformation from "./PlaylistInformation";
import Navbar from "../Common/Navbar";

const PlaylistsLayout = () => {
  return (
    <>
      <Navbar />
      <div className="flex h-full">
        <aside className="m-0 hidden w-[40%] sm:block">
          <PlaylistsList />
        </aside>
        <main className="w-full">
          <PlaylistInformation />
        </main>
      </div>
    </>
  );
};

export default PlaylistsLayout;
