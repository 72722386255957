import PlaylistItem from "./PlaylistItem";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPlaylists, clearSelectedPlaylist } from "../../state/playlistState/playlistActions";
import { clearTracks } from "../../state/trackState/trackActions";
import { useNavigate } from "react-router";

const PlaylistsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.playlists.playlists);
  const selectedPlaylist = useSelector(
    (state) => state.playlists.selectedPlaylist
  );
  
  useEffect(() => {
    document.title = `${(selectedPlaylist !== undefined && Object.keys(selectedPlaylist).length > 0 ? selectedPlaylist.name : "Homepage")} - Toolkit for Spotify`;
  }, [selectedPlaylist]);

  useEffect(() => {
    if (playlists.length === 0) {
      dispatch(getPlaylists());
    }
  });

  const handleClick = (playlist) => {
    if (playlist === selectedPlaylist) {
      dispatch(clearTracks());
      dispatch(clearSelectedPlaylist());
      navigate("/");
    } else {
      navigate(`/playlist/${playlist.id}`);
    }
  };

  const createListOfPlaylists = () => {
    if (playlists.length === 0) {
      return <p>Loading...</p>;
    } else {
      return playlists.map((playlist) => {
        return (
          <PlaylistItem
            key={playlist.id}
            className={selectedPlaylist === playlist ? "bg-[#414141]" : ""}
            playlist={playlist}
            onClick={() => handleClick(playlist)}
          />
        );
      });
    }
  };

  return (
    <>
      <div className="flex h-[60px] m-0">
        <span className="my-0 ml-2 self-center text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">Your owned playlists</span>
      </div>
      <div className="playlistsList my-1">{createListOfPlaylists()}</div>
    </>
  );
};

export default PlaylistsList;
