import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getOAuthCode } from "../state/tokenState/tokenActions";
import { useDispatch } from "react-redux";

const LoginStatusPage = (props) => {
  const [searchParams] = useSearchParams();
  const code = useState(searchParams.get("code"));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      dispatch(getOAuthCode(code[0]));
      navigate("/");
    }
  }, [dispatch, navigate, code]);
};

export default LoginStatusPage;
