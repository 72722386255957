import axios from "axios";

export const getTracksFromSpotify = (playlistId, limit = 50, offset = 0) => {
  return async (dispatch) => {
    const tracks = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/tracks/${playlistId}?limit=${limit}&offset=${offset}`,
      {
        withCredentials: true,
      }
    );

    if (offset === 0) {
      dispatch(setTracks(tracks.data.message));
    }
    else {
      dispatch(addTracks(tracks.data.message));
    }
  };
};

export const setTracks = (value) => {
  return { type: "setTracks", payload: value };
};

export const addTracks = (value) => {
  return { type: "addTracks", payload: value };
};

export const removeTracksFromPlaylist = (playlistId, removedTracks) => {
  return async (dispatch) => {
    const trackURIs = removedTracks.map((track) =>{
        return { 
          uri: track.props.track.track.uri, 
          positions: track.props.track.positions 
        }
      }
    );

    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/tracks/${playlistId}`, {
      data: {
        trackURIs,
      },
      withCredentials: true,
    });

    dispatch(removeTracks(removedTracks));
  };
};

export const removeTracks = (tracks) => {
  return { type: "removeTracks", payload: tracks };
}

export const clearTracks = () => {
  return { type: "clearTracks" };
}