import { combineReducers } from "redux";

// Import all reducers here:
import tokenReducer from "./tokenState/tokenReducer";
import playlistReducer from "./playlistState/playlistReducer";
import trackReducer from "./trackState/trackReducer";

const mainReducer = combineReducers({
  // Add reducers here
  token: tokenReducer,
  playlists: playlistReducer,
  tracks: trackReducer,
});

export default mainReducer;
