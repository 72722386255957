import React from "react";
import ReactDOM from "react-dom/client";

import { compose, createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";

import App from "./App";

import "./stylesheets/styles.css";

import mainReducer from "./state/combineReducers";
import thunkMiddleware from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = (store) => (next) => (action) => {
  let result = next(action);
  return result;
};

const store = createStore(
  mainReducer,
  composeEnhancers(applyMiddleware(logger, thunkMiddleware))
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
