const initialPlaylistState = {
  playlists: [],
  selectedPlaylist: {},
  searchBox: false,
};

const playlistReducer = (state = initialPlaylistState, action) => {
  switch (action.type) {
    case "setPlaylists":
      return {
        ...state,
        playlists: action.payload,
      };
    case "selectPlaylist":
      return {
        ...state,
        selectedPlaylist: action.payload,
      };
    case "setSearchBox":
      return {
        ...state,
        searchBox: !state.searchBox,
      };
    case "clearSelectedPlaylist":
      return {
        ...state,
        selectedPlaylist: {},
      };

    default:
      return state;
  }
};

export default playlistReducer;
