const initialTokenState = {
  tokens: {},
};

const tokenReducer = (state = initialTokenState, action) => {
  switch (action.type) {
    case "setToken":
      return {
        ...state,
        tokens: action.payload,
      };

    default:
      return state;
  }
};

export default tokenReducer;
