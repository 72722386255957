const TrackItem = (props) => {
  const trackTitle = () => {
    if (props.track.track.name.length > 60) {
      return props.track.track.name.slice(0, 60) + "...";
    } else {
      return props.track.track.name;
    }
  };

  const trackArtists = () => {
    if (props.track.track.artists.length > 1) {
      return props.track.track.artists.map((artist) => artist.name).join(", ");
    } else {
      return props.track.track.artists[0].name;
    }
  }

  return (
    <a href={props.track.track.external_urls.spotify} target="_blank" rel="noreferrer" className="flex mx-1 p-2 rounded-md cursor-pointer hover:bg-[#353535] transition-all duration-200">
      <img
        className="w-[50px] h-[50px] mr-2"
        src={
          props.track.track.album.images[
            props.track.track.album.images.length - 1
          ]?.url
        }
        alt="playlist icon"
      />
      <div className="flex flex-col">
        <h3 className="text-md">{trackTitle()}</h3>
        <h3 className="text-xs text-zinc-400">{trackArtists()}</h3>
      </div>
    </a>
  );
};

export default TrackItem;
