import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import LoginPage from "./LoginPage";
import PlaylistsLayout from "../components/Playlist/PlaylistsLayout";
import { selectPlaylist } from "../state/playlistState/playlistActions";
import { setToken } from "../state/tokenState/tokenActions";
import Cookies from "js-cookie";


const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playlistId } = useParams();
  const playlists = useSelector((state) => state.playlists.playlists);
  const tokens = useSelector((state) => state.token.tokens);

  useEffect(() => {
    if (Object.keys(tokens).length === 0 && Cookies.get("access_token")) {
      const cookies = {};
      Object.keys(Cookies.get()).forEach((cookieName) => {
        cookies[cookieName] = Cookies.get(cookieName);
      });
      dispatch(setToken(cookies));
    }
  }, [dispatch, tokens])

  useEffect(() => {
    if (Object.keys(playlists).length > 0) {
      if (playlistId) {
        const foundPlaylist = playlists.find((playlist) => playlist.id === playlistId);
        if (foundPlaylist !== undefined) {
          dispatch(selectPlaylist(foundPlaylist));
        }
        else {
          navigate("/");
        }

      } 
    }
  }, [navigate, dispatch, playlists, playlistId]); 

  const showBody = () => {
    if (Object.keys(tokens).length > 0) {
      return <PlaylistsLayout />;
    } else {
      return <LoginPage />;
    }
  };

  return <div className="flex flex-col w-full h-[100vh]">{showBody()}</div>;
};

export default HomePage;
