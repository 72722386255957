import { useState, useRef } from "react";
import TrackItem from "../../Track/TrackItem";
import { removeTracksFromPlaylist } from "../../../state/trackState/trackActions";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useEffect } from "react";
import clickOutside from "../../../utils/clickOutside";

const DuplicateCheck = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const duplicateSongModal = useRef(null);
  const [duplicateSongs, setDuplicateSongs] = useState([<div key="spinner" className="d-flex justify-content-center p-3"></div>]);
  const tracks = useSelector((state) => state.tracks.tracks);
  const selectedPlaylist = useSelector((state) => state.playlists.selectedPlaylist);

  useEffect(() => {
    clickOutside(duplicateSongModal, () => setShow(false));
  }, [duplicateSongModal]);

  const handleClose = () => {
    setShow(false);
    setDuplicateSongs([]);
  };
  const handleShow = () => {
    setShow(true);
    checkDuplicates();
  }
  const handleDelete = () => {
    setShow(false);
    dispatch(removeTracksFromPlaylist(selectedPlaylist.id, duplicateSongs));
  };

  const checkDuplicates = () => {
    let nonDuplicates = [];
    let duplicates = [];
    let positions = 0;

    tracks.forEach((track) => {
      let dup = false;
      for (let i = 0; i < nonDuplicates.length; i++) {
        if (nonDuplicates[i].track.name === track.track.name) {
          const dupArtists = nonDuplicates[i].track.artists;
          const trackArtists = track.track.artists;
          const checkedArtists = [];

          for (let j = 0; j < dupArtists.length; j++) {
            for (let k = 0; k < trackArtists.length; k++) {
              if (dupArtists[j].id === trackArtists[k].id) {
                checkedArtists.push(dupArtists[j]);
              }
            }
          }

          if (
            checkedArtists.length === dupArtists.length &&
            checkedArtists.length === trackArtists.length
          ) {
            dup = true;
          }
        }
      }

      if (dup) {
        duplicates.push({ ...track, positions: [positions] });
      } else {
        nonDuplicates.push(track);
      }

      positions++;
    });

    if (duplicates.length > 0) {
      let trackNumber = 0;
      setDuplicateSongs(
        duplicates.map((track) => {
          trackNumber++;
          return <TrackItem track={{...track}} key={track.track.id + trackNumber} />;
        })
      );
    }
    else {
      setDuplicateSongs([<p key="non">No duplicates found</p>]);
    }
  };

  return (
    <>
      <button onClick={handleShow} type="button" className="inline-flex justify-center items-center rounded-md border-2 border-transparent shadow-sm px-4 py-1 text-base text-white outline-zinc-700 hover:outline transition-all duration-75">
        <HiOutlineDuplicate className="mr-1" /> Duplicate song check
      </button>
      {show && (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-[#181818] bg-opacity-60 backdrop-blur-[2px] transition-opacity"></div>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center h-2/3 p-2 text-center sm:p-0">
              <div ref={duplicateSongModal} className="relative bg-[#2b2b2b] rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">Duplicate song check</h3>
                <hr className="my-2 border-zinc-400" />
                <div className="mt-2">
                  {duplicateSongs}
                </div>
                <div className="flex gap-2 justify-end">
                  <button onClick={handleClose} type="button" className="mt-3 inline-flex justify-center rounded-md border border-zinc-500 shadow-sm px-4 py-2 bg-white text-base font-medium transition-all duration-200 text-gray-700 hover:bg-zinc-400">Cancel</button>
                  {duplicateSongs[0].props.children !== 'No duplicates found' && <button onClick={handleDelete} type="button" className="mt-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 shadow-sm px-4 py-2 text-base font-medium transition-all duration-200 text-white hover:bg-red-500">Remove duplicates</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DuplicateCheck;
