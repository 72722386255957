const PlaylistItem = (props) => {
  const playlistTitle = () => {
    if (props.playlist.name.length > 40) {
      return props.playlist.name.slice(0, 40) + "...";
    } else {
      return props.playlist.name;
    }
  };

  return (
      <div className={"flex mx-1 p-2 rounded-md hover:bg-[#353535] cursor-pointer transition-all duration-200 " + props.className} onClick={props.onClick}>
        <img
          className="w-[50px] h-[50px] mr-2"
          src={props.playlist.images[props.playlist.images.length - 1].url}
          alt="playlist icon"
        />
        <p className="truncate">{playlistTitle()}</p>
      </div>
  );
};

export default PlaylistItem;
